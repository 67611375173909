/**
 * Email Validation
 * Source: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
 */

const emailRegex = new RegExp(
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
);

export const validateEmail = (input: string) => {
  return emailRegex.test(input);
};

export const validatePasswordOrReturnError = (input: string): true | string => {
  //   if (passwordRegex.test(input)) {
  //     return true;
  //   }
  if (input.length < 6) {
    return `Password must have at least 6 characters`;
  }
  return true;
  //   else if (!hasLowerCaseLetter(input)) {
  //     return `Password must have at least 1 lowercase letter`;
  //   } else if (!hasUpperCaseLetter(input)) {
  //     return `Password must have at least 1 uppercase letter`;
  //   } else if (!hasSpecialCharacter(input)) {
  //     return `Password must have at least 1 special character`;
  //   } else if (!hasDigit(input)) {
  //     return `Password must have at least 1 number`;
  //   }
  //   return "Error";
};
