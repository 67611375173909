import React from 'react';
import AuthView from 'components/views/Auth';
import { NextPageWithLayout } from 'types/page';
import { NextSeo } from 'next-seo';

/**
 * Home: The Landing page of the web app
 * @return {NextPageWithLayout}
 */
const Home: NextPageWithLayout<any> = () => (
  <>
    <NextSeo />
    <AuthView />
  </>
);

export default Home;
