// Auth
import React, { ChangeEventHandler, useEffect, useState } from 'react';
import styles from './Auth.module.sass';
import cn from 'classnames';
import Input from 'components/common/Input';
import { TermsAndPolicy } from './TermsAndPolicy';
import { useAuth } from 'hooks/useAuth';
import { validateEmail, validatePasswordOrReturnError } from 'utils/validators';
import Loader from 'components/common/Loader';
import { AUTH_STATUS } from 'types/auth';
import { useRouter } from 'next/router';

export interface AuthProps {}

type FormType = 'sign-up' | 'log-in';

const Auth: React.FC<AuthProps> = ({}) => {
  const {
    login,
    signup,
    authState: { status },
  } = useAuth();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [authState, setAuthState] = useState<FormType>('sign-up');
  const [{ email, password }, setAuthForm] = useState({
    email: '',
    password: '',
  });
  const [emailError, setEmailError] = useState<string | undefined>();
  const [passwordError, setPasswordError] = useState<string | undefined>();

  useEffect(() => {
    if (status === AUTH_STATUS.AUTHENTICATED) {
      router.push('/ai');
    }
  }, [status]);

  const handleTabClick: React.MouseEventHandler<HTMLButtonElement> = ({
    currentTarget: { id },
  }) => {
    setAuthState(id as FormType);
  };

  const handleEmailKeyUp: React.KeyboardEventHandler<HTMLInputElement> = ({
    key,
  }) => {
    if (key === 'Enter' || key === 'Tab') {
      document.getElementById('password')?.focus();
    }
  };

  const handlePasswordKeyUp: React.KeyboardEventHandler<HTMLInputElement> = ({
    key,
  }) => {
    if (key === 'Enter') {
      if (authState === 'sign-up') {
        handleSignUp();
      } else {
        handleLogin();
      }
    }
  };

  const checkErrors = () => {
    const isValidEmail = validateEmail(email);
    setEmailError(isValidEmail ? undefined : 'Invalid email');
    const isValidPasswordOrError = validatePasswordOrReturnError(password);
    setPasswordError(
      typeof isValidPasswordOrError === 'string'
        ? isValidPasswordOrError
        : undefined
    );
    return isValidEmail && typeof isValidPasswordOrError !== 'string';
  };

  const handleLogin = async () => {
    if (checkErrors()) {
      setIsLoading(true);
      await login(email, password);
      setIsLoading(false);
    }
  };

  const handleSignUp = async () => {
    if (checkErrors()) {
      setIsLoading(true);
      await signup(email, password);
      setIsLoading(false);
    }
  };

  const handleEmailAuth = async () => {
    if (authState === 'sign-up') {
      handleSignUp();
    } else {
      handleLogin();
    }
  };

  const onInputChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { id, value },
  }) => {
    setAuthForm((form) => ({ ...form, [id]: value }));
    if (id === 'email') {
      setEmailError(undefined);
    } else if (id === 'password') {
      setPasswordError(undefined);
    }
  };

  return (
    <div className={styles.auth}>
      <img alt="trend studio" src="/trend-studio.png" height={20} />
      <h1>Generate free photos with AI</h1>
      <div className={styles.authCard}>
        <div className={styles.tabBar}>
          <button
            className={cn(styles.tab, {
              [styles.selected]: authState === 'sign-up',
            })}
            id="sign-up"
            onClick={handleTabClick}
          >
            Sign Up
          </button>
          <button
            className={cn(styles.tab, {
              [styles.selected]: authState === 'log-in',
            })}
            id="log-in"
            onClick={handleTabClick}
          >
            Log In
          </button>
        </div>
        {!isLoading ? (
          <>
            <Input
              type="email"
              id="email"
              placeholder="Company Email"
              value={email}
              onChange={onInputChange}
              onKeyUp={handleEmailKeyUp}
              wrapperClassName={styles.inputWrapper}
              showErrorText
              error={emailError}
            />
            <Input
              type="password"
              id="password"
              placeholder="Password"
              value={password as string}
              onChange={onInputChange}
              onKeyUp={handlePasswordKeyUp}
              wrapperClassName={styles.inputWrapper}
              showErrorText
              error={passwordError}
            />
          </>
        ) : (
          <div className={styles.loadingContainer}>
            <Loader />
          </div>
        )}
        <button
          className={cn(styles.actionButton, 'button-3d full-width')}
          onClick={handleEmailAuth}
          disabled={isLoading || Boolean(!email || !password)}
        >
          {authState.replace('-', ' ')}
        </button>
        <div className={styles.footer}>
          <TermsAndPolicy />
        </div>
      </div>
    </div>
  );
};

export default Auth;
